.header-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* width: calc(100vw - 130px); */
  width: 100%;
}

.back-btn {
  background: #ffffff !important;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px !important;
  width: 40px;
  height: 40px;
  margin-left: 10px !important;
}

/* @media (max-width: 768px) {
  .header-box {
    width: 95vw;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .header-box {
    width: calc(100vw - 130px);
  }
} */

.header-text {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: center;
}

.flexin {
  flex-grow: 1;
}

.header-logo-box {
  /* display: flex;
  justify-content: space-between; */
  cursor: pointer;
  padding-right: 10px;
}

.header-logo-box img {
  padding-left: 15px;
}

.header_button_main_box{
  display: flex;
  align-items: center;
}

.header_Button_box{
display: flex;
    gap: 10px;
    background: #EB7930;
    height: 40px;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    color: white;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
}

.clickable-text-button {
  font-family: "Montserrat-Bold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: 0.5px;
  color: #eb6e1f !important;
  cursor: pointer;
  display: flex;
}
