.OHS_Upload_main_box {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    height: 40px;
    background: white;
    border: 1px solid #EB7930;
    border-radius: 10px;
    /* margin: 10px; */
    justify-content: space-between;
    cursor: pointer;
}

.OHS_popup_main_box {
    position: absolute;
    left: -170px;
    width: 220px;
    top: -45px;
}

.OHS_popup_text_box {
    padding: 10px;
    border-radius: 10px;
    background: white;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #EB7930;
}

.OHS_popup_tips {
    border: 10px solid transparent;
    border-top-color: #EB7930 !important;
    border-bottom: none;
    width: 2px;
    margin: 0px 175px;
}

.File_upload_title {
    width: 100%;
    margin: 0px;
    color: rgb(92, 90, 90);
    font-size: 15px;
    text-shadow: rgba(0, 0, 0, 0) 0px 0px 2px;
    font-weight: 500;
    text-align: left;
}

.File_upload_detail_main_box {
    padding: 10px 20px;
    border: 1px solid #EB7930;
    border-radius: 10px;
    height: 42px;
    margin-bottom: 10px;
}

.File_type_box {
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 54px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EB7930;
    border-radius: 100px;
    background: #EB7930;
    color: white;
}

.File_detail_main_box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.File_detail_name {
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #3F3F3F;
}

.File_detail_size {
    font-family: 'Montserrat-Medium';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #727272;
}

.File_detail_delete {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .File_detail_main_box{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .File_Detail_main_item_box{
        width: calc(100% - 80px);
    }

    .width_download_button{
        width: 70px;
    }
}

@media (max-width: 769px) {
    .File_upload_detail_main_box{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .File_detail_main_box{
        margin-top: 15px;
        max-width: calc(100vw - 45px);
    }
}