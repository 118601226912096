.more_main_box {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 80px;
  background: #fafafa;
  margin-bottom: 2px;
  cursor: pointer;
}

.more_margin {
  margin: 16px;
}

@media (max-width: 769px) {
  .More_margin {
    margin-top: 80px;
  }
}

.more_sec_box {
  width: 90%;
  display: flex;
  padding-left: 16px;
  align-items: center;
}

.more_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.more_icon_box {
  width: 10%;
  padding-right: 16px;
  cursor: pointer;
}

.more_logout {
  background: #eb7930;
  border-radius: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 16px;
  color: white;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.mobile-content .more_main_box-0 {
  padding-top: 75px;
}

.ContactUs{
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  flex-wrap: wrap;
  margin: 17px 20% 20px;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #AEB4BE;
}

@media (max-width: 425px) {
  /* .ContactUs{
   margin-top: 70%;
   height: 0;
  } */
}

.more_custom{
  height: 100%;
}

.more_custom .dashboard_header_box{
  position: absolute;
  top: 0;
}

.ContactUs_fiedls_main_box{
  margin: 0px 20%;
  text-align: left;
}

.ContactUs_Text_box{

}

.ContactUs_title{
  font-family: "Montserrat-Bold";
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 8px;
}

.ContactUs_Text_field{
 border-radius: 8px;
 height: 48px;
}

.ContactUs_dropdown {
    height: 48px;
    background-color: #FFFFFF !important;
    box-shadow: none;
    border: 1px solid #E4E1DE !important;
    background-image: none !important;
    border-radius: 8px !important;
}

.ContactUs_dropdown:focus {
    box-shadow: none;
}

.ContactUs_dropdown .k-input-button .k-icon.k-i-caret-alt-down:before {
    content: none !important;
}

.ContactUs_dropdown .k-input-button .k-icon.k-i-caret-alt-down {
    background-image: url("/src/assets/images/incident/dropdown-arrow.svg") !important;
    background-repeat: no-repeat !important;
    margin-top: 15px !important;
}

.ContactUs_textarea {
    height: auto !important;
    background: #fff !important;
    border: 1px solid #E4E1DE !important;
    border-radius: 8px !important;
}

.ContactUs_main_box{
  height: calc(100vh - 81px);
}

.ContactUs_partent_box{
  overflow-y: scroll;
  height: calc(100% - 145px);
}

.ContactUs_attachment_main_box{
  min-height: 48px;
  border: 1px solid #E4E1DE;
  border-radius: 8px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ContactUs_attachment_upload_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat-Medium";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}

.ContactUs_upload_button{
  display: flex;
  align-items: center;
  gap: 10px;
}

.ContactUs_attachment_detail_main_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E4E1DE;
  padding-top: 10px;
}

.ContactUs_attachment_detail_text1{
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
}

.ContactUs_attachment_detail_text2{
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin-top: 10px;
}

.ContactUs_footer_main_box{
  position: sticky;
  bottom: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 25px;
  padding: 0px 25px;
  box-shadow: 0px -1px 0px 0px #DDDDDD;
  z-index: 99;
  background: white;
}

.ContactUs_footer_cancel_button{
  border: 1px solid #EB6E1F;
  width: 165px;
  height: 48px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat-Bold";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #EB6E1F;
  cursor: pointer;
}

.ContactUs_footer_submit_button{
  border: 1px solid #EB6E1F;
  width: 165px;
  height: 48px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat-Bold";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  background: #EB6E1F;
  cursor: pointer;
}

.ContactUs_error_message{
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  font-style: italic;
  color: red;
  margin-top: 10px;
}

.ContactUs_popup_main_box {
    position: absolute;
    /* left: -170px; */
    width: 220px;
    top: -50px;
    right: 0;
}

.ContactUs_popup_text_box {
    padding: 10px;
    border-radius: 10px;
    background: #E4E1DE;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
}

.ContactUs_popup_tips {
    border: 10px solid transparent;
    border-top-color: #E4E1DE !important;
    border-bottom: none;
    width: 2px;
    margin: 0px 175px;
}

.ContactUs_popup_parent_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 30px 10px;
}

.ContactUs_popup_title{
  font-family: "Montserrat-SemiBold";
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.ContactUs_popup_detail{
  font-family: "Montserrat-Medium";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.ContactUs_popup_close_button{
  width: 150px;
  height: 40px;
  border: 1px solid;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 27%;
  color: #EB7930;
  font-family: "Montserrat-Bold";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
}

.ContactUs_popup_cross_button{
  position: absolute;
  right: 20px;
  font-family: "Montserrat-Bold";
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0px;
  cursor: pointer;
}

.ContactUs_Text_field .k-input-solid{
  height: auto !important;
}

.morScrollbarDIV::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.morScrollbarDIV::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.morScrollbarDIV::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.morScrollbarDIV::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.notification_item_main_box{
  display: flex;
  gap: 20px;
  height: 80px;
  align-items: center;
  margin: 0px 20px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.notification_item_title{
  font-family: "Montserrat-Bold";
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}

@media (max-width: 769px) {
  .ContactUs_fiedls_main_box {
    margin: 0px 10px;
    text-align: left;
  }
  .ContactUs_partent_box{
    overflow: scroll;
    height: calc(100% - 145px);
  }
  .ContactUs_main_box{
    height: calc(100vh - 57px);
  }
  .ContactUs_footer_main_box{
    justify-content: space-around;
    padding: unset;
  }
}