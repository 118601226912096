.no-permission-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 769px) {
  .no-permission-div {
    height: calc(100vh - 81px);
  }
}

@media (max-width: 768px) {
  .no-permission-div {
    height: calc(100vh - 57px);
  }
}
