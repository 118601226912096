@tailwind utilities;

.k-input-solid {
    min-height: 48px;
}

.work_header {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 2%);
}

.work-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    height: 92vh;
    height: calc(.92 * var(--doc-height));
}

.Work_detail_data_main_box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin: 16px 0px 0px;
    gap: 16px;
}

.Work_detail_title {
    width: calc(70% - 32px);
    background: #F3F3F3;
    padding: 16px;
}

.Work_error_message {
    margin-top: 10px;
    font-size: 12px;
    color: red;
    font-family: 'Montserrat-Medium';
    font-style: italic;
}

.Work_detail_data_child_box {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 7px 0px;
    border-bottom: 1px solid #E4E1DE;
}

.Work_detail_data_first_child {
    width: 50%;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #776F6A;
}

.Work_detail_data_last_child {
    width: 50%;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Work_main_box {
    display: flex;
    background-color: white;
    height: 92vh;
    height: calc(.92 * var(--doc-height));
}

.Work_detail_box {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Work_download_button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EB6E1F;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    width: 63px;
}

.Work_leftBox {
    width: 30%;
}

.Work_rightBox {
    width: 70%;
}

.Work_chips_box {
    display: flex;
    height: 43px;
    margin: 10px 0px;
    overflow-x: hidden;
    width: calc(100% - 50px);
    align-items: center;
}

.Work_chips_sec_box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-left: 20px;
    cursor: pointer;
    min-height: 32px;
}

.Work_chips_text {
    padding-left: 15px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
}

.Work_chips_text1 {
    margin-left: 5px;
    min-width: 22px;
    margin-right: 10px;
    border-radius: 100px;
    height: 21px;
    width: 23px;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Work_chips_sec_box_active {
    background-color: #EB7930;
}

.Work_chips_text_active {
    color: white;
}

.Work_chips_text1_active {
    background: white;
    color: #EB7930;
}

.Work_chips_sec_box_unactive {
    background: #FFFFFF;
}

.Work_chips_text_unactive {
    color: #5C5146;
}

.Work_chips_text1_unactive {
    background: #EFEFEF;
    color: #5C5146;
}

.box-active {
    background-color: #d2d0d0;
}

.Work_sidebar_main_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #FFFFFF;
    margin: 0px 20px;
    padding: 20px;
}

.Work_sidebar_main_box:hover {
    background: #EFEFEF;
}

.Work_sidebar_first_child {
    width: 80%;
    text-align: left;
}

.work-slider-items-wrapper {
    overflow-y: auto;
}

.work-dec-loader {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 270px);
    height: calc(var(--doc-height) - 270px);
}

.Work_sidebar_date {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
    color: #776F6A;
    margin-bottom: 8px;
}

.Work_sidebar_action_box {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #EB7930;
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #EB7930;
    border-radius: 100px;
    max-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    cursor: pointer;
}

.Work_sidebar_title {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 8px;
}

.Work_sidebar_package {
    font-family: 'Montserrat-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.Work_margin_right {
    margin-right: 8px;
}

.Work_sidebar_building {
    font-family: 'Montserrat-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 8px;
}

.Work_sidebar_Hira_status {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #D12D2F;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.Work_sidebar_second_child {
    width: 20%;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #EB7930;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

@media (min-width: 1440px) {
    .Work_rightBox {
        width: 70%;
    }

    .Work_leftBox {
        width: 30%;
    }
}

@media (max-width: 1025px) {
    .Work_rightBox {
        width: 60%;
    }

    .Work_leftBox {
        width: 40%;
    }
}

@media (max-width: 769px) {
    .Work_main_box {
        /* margin: 16px 17px 0px 17px; */
        background: white;
        padding: 24px 0px;
    }
}

.unresize {
    height: calc(var(--doc-height) - 205px);
}

.resize {
    height: calc(var(--doc-height) - 288px);
}

.Work_sidebar_detail_box {
    overflow-y: auto;
    text-align: left;
}

.Work_sidebar_detail_item_box {
    /* padding: 17px 16px;
    margin-bottom: 4px; */
    background: #FAFAFA;
    cursor: pointer;
}

.Work_sidebar_detail_item_box_active {
    /* padding: 17px 16px;
    margin-bottom: 4px; */
    background-color: #c2bebe;

    cursor: pointer;
}

.Work_main_container {
    height: calc(var(--doc-height) - 162px);
    overflow-y: auto;
    overflow-x: hidden;
}

.Work_create {
    height: calc(100vh - 57px);
    height: calc(var(--doc-height) - 57px);
}

.type-form-label-child-label {
    font-size: 12px;
    line-height: 18px;
    font-family: "Montserrat-Medium";
}

.Work_create_main_box {
    display: flex;
    width: 100%;
    justify-content: center;
    /* margin-top: 38px; */
    flex-direction: column;
    align-items: center;
    background-color: white;
    /* padding: 0px 30px; */
}

.Work-main {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.Work_create_item_box {
    width: 70%;
    margin-bottom: 30px;
    max-width: 640px;
}

.Work_create_item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.Work_create_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #EB7930;
    border-radius: 12px;
    height: 64px;
    padding: 0px 17px;
    cursor: pointer;
}

.Work_create_item_inner_box {
    display: flex
}

.Work_create_item_inner_text {
    margin-left: 20px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #EB7930;
    text-align: left;
}

.detail-row {
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    gap: 17px;
    text-align: left;
}

.width-50 {
    width: 50%;
}

.detail-column {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.Work_create .header-text {
    text-align: left;
    padding-left: 10px;
}

.detail-item-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.detail-item-label {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 18px;
    text-align: left;
}

.detail_note {
    font-family: 'Montserrat-Medium';
    font-size: 14px;
}

.detail-radio-div {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.detail-radio-div .k-radio:checked,
.detail-radio-div .k-radio.k-checked {
    background-color: #2B63CA;
    border-color: #2B63CA;
}

.type-form-div {
    display: flex;
    flex-direction: column;
    background-color: #EFEFEF;
    padding: 20px;
    gap: 10px;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    /* width: 600px; */
}

.type-form-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.type-form-label {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    width: calc(100% - 24px);
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
}

@media (max-width: 425px) {
    .type-form-label {
        font-size: 14px;
    }
}

.type-form-label-child {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 8px;
}

.type-form-label1 {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 18px;
}

.type-form-label2 {
    font-family: 'Montserrat-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000;

}

.type-form-files {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.type-form-file-item {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 8px;
    justify-content: space-between;
}

.type-form-name-type {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: calc(100% - 70px);
}

.form-file-type {
    padding: 0px 12px;
    background-color: #EB6E1F;
    border-radius: 100px;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    min-width: 35px;
}

.form-upload-btn {
    display: flex;
    flex-direction: row;
    color: #EB6E1F;
    color: #EB6E1F;
    gap: 5px;
    height: 16px;
    justify-content: center;
    vertical-align: top;
}

.detail-form-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
}

.type-radio-btn {
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
}

.work-container {
    display: block;
    position: relative;
    /* padding-left: 35px; */
    /* margin-bottom: 12px; */
    cursor: pointer;
    border-radius: 100px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 24px;
}

/* Hide the browser's default checkbox */
.work-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.work-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border: 1px solid #e4e1de;
    border-radius: 100px;
}

/* When the checkbox is checked, add a blue background */
.work-container input:checked~.work-checkmark {
    background-color: #2B63CA;
}

/* Create the checkmark/indicator (hidden when not checked) */
.work-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.work-container input:checked~.work-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.work-container .work-checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;

}

.details-radio {
    width: 24px;
    height: 24px;
    border: 1px solid #ccc;
}

.detail-radio-div {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.detail-radio-div .k-radio-label {
    font-family: 'Montserrat-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #000000;
    padding-left: 16px;
}

.hira-items {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.width-100 {
    width: 100%;
}

.hira-details-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.form-designer__viewport .detail-activity-list {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.form-designer__viewport .detail-activity-list div {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    border-radius: 100px;
    background-color: #EB7930;
    padding: 15px 20px;
    color: #FFFFFF;
}

.form-designer__viewport .\!h-\[110px\].k-input-solid {
    /** Need to use important here to override rule in auth.css */
    height: 110px !important;
}

.form-designer__viewport .OHS_FORM_validation {
    display: none;
}

.form-designer__viewport--touched .OHS_FORM_validation {
    display: block;
}

.detail-machinery-box {
    /* background-color: #fafafa; */
    /* padding: 16px; */
    display: flex;
    flex-direction: column;
    gap: 18px;
    /* border-radius: 12px; */
}

.work-textbox {
    background-color: #FFFFFF;
    border: 1px solid #E4E1DE;
    border-radius: 8px;
    height: 48px;
}

.machinery-delete-button {
    background-color: #FFFFFF;
    border: 1px solid #EB6E1F;
    height: 48px;
    border-radius: 12px;
}

.addmachine {
    background-color: #FFFFFF;
    color: #EB6E1F;
    height: 48px;
    border-radius: 100px;
    border: 1px solid #EB6E1F;
}

.detail-textbox {
    background-color: #FFFFFF;
    border: 1px solid #E4E1DE;
    border-radius: 8px;
}

.detail-item .k-focus {
    box-shadow: none;
}

.remark-textarea {
    height: auto;
    background: #fff;
    border: 1px solid #E4E1DE;
    border-radius: 8px;
}

.work-approved-item {
    display: flex;
    flex-direction: row;
    background-color: #EFEFEF;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 16px;
    width: calc(100% - 16px);
    border-radius: 8px;
}

.work-approved-item-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 85%;
}

.approved-user-role {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5C5146;
}

.approved-user-name {
    font-family: 'Montserrat-Semibold';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: left;
    letter-spacing: 0.5px;
    color: #5C5146;
}

.approved-date {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.5px;

    /* CEreal/Gray2 */

    color: #5C5146;
}

.work-approved-status {
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.5px;
}

.detail-item .map-container {
    height: 400px;
    margin: 10px 0px 10px 0px;
}

@media (max-width: 769px) {
    .detail-column {
        width: 100%;
    }

    .detail-radio-div {
        width: 100%;
    }
}

@media (max-width: 1250px) {
    .Work_create_item_box {
        width: 90%;
    }

    .detail-footer {
        background-color: #2B63CA;
    }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
    .work-slider-items-wrapper {
        height: calc(100vh - 251px);
        height: calc(var(--doc-height) - 251px);
        overflow-y: auto;
    }

    .Work_footer_main_box {
        width: 80%;
    }

    .detail-footer {
        background-color: #ca2b9d;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .Work_footer_main_box {
        width: 80%;
    }

    .detail-footer {
        background-color: #5dca2b;
    }
}



.Work_footer_main_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    width: calc(100% - 145px);
    align-items: center;
    padding: 16px 22px;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -1px 0px #DDDDDD;
}

@media (max-width: 769px) {
    .Work_footer_main_box {
        width: calc(100% - 44px);
        bottom: 0;
        /* bottom: 57px; */
        position: sticky;
    }

    .Work_create_main_box {
        padding: 0;
    }
}

.footer-left {
    display: flex;
}

.footer-right {
    display: flex;
    gap: 8px;
}

.Work_footer_button {
    background: #FFFFFF;
    border: 1px solid #EB6E1F;
    border-radius: 100px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 165px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #eb7930;
}

.ohs__actions .Work_footer_button:hover {
    border: 1px solid #ee640e;
    color: #ee640e;
    background: #FFFFFF;
}

.submit-button {
    background-color: #EB7930;
    color: #FFFFFF;
}

.ohs__actions .submit-button:hover {
    border: 1px solid #ee640e;
    color: #FFFFFF;
    background: #ee640e;
}

.Work_popup_main_box {
    position: absolute;
    left: -170px;
    width: 220px;
    top: -45px;
}

.Work_popup_text_box {
    padding: 10px;
    border-radius: 10px;
    background: white;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
}

.Work_popup_tips {
    border: 10px solid transparent;
    border-top-color: white;
    border-bottom: none;
    width: 2px;
    margin: 0px 175px;
}

.WorkDE_footer_main_box {
    display: flex;
    position: fixed;
    left: 100px;
    align-items: center;
    padding: 16px 22px;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -1px 0px #DDDDDD;
    justify-content: space-between;
    width: calc(100% - 145px);
}

.Work_save_button {
    background: #EB6E1F;
    border: 1px solid #EB6E1F;
    border-radius: 100px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 165px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    max-width: 120px;
    color: white;
    cursor: pointer;
}

.Sitrep_sidebar_add_icon_box {
    position: absolute;
    bottom: 75px;
    /* padding-left: 28%; */
    right: calc(100% - 245px);
}

.Sitrep_sidebar_add_icon {
    position: absolute;
    bottom: 5px;
    background-color: #eb7930;
    color: #ffffff;
    width: 72px;
    height: 72px;
    box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
    border-radius: 100px;
    left: 45%;
}

@media (max-width: 769px) {
    .Work_sidebar_detail_box {
        height: calc(100vh - 183px);
        height: calc(var(--doc-height) - 183px);
    }

    .Sitrep_sidebar_add_icon_box {
        left: 0;
        right: -40px;
        margin-left: auto;
        margin-right: auto;
    }

    .mobile_box {
        width: 100%;
    }

    .mobile_footer {
        flex: 1;
        min-width: unset;
    }

    .WorkDE_footer_main_box {
        width: calc(100% - 45px);
        justify-content: space-between;
        position: sticky;
        bottom: 0;
        /* bottom: 57px; */
        left: 0;
        /* padding-bottom: 20px; */
    }

    .Work_main_container {
        height: calc(var(--doc-height) - 162px);
    }
}

@media (max-width: 425px) {
    .Sitrep_sidebar_add_icon_box {
        left: 0;
        right: 0px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 375px) {
    .Sitrep_sidebar_add_icon_box {
        left: 0;
        right: 20px;
        margin-left: auto;
        margin-right: auto;
    }
}


.wdc_scroll::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.wdc_scroll::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.wdc_scroll::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.wdc_scroll::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.date_absolute .float-datePicker {
    position: unset;
}

.timePicker,
.datePicker {
    border: 1px solid #e4e1de;
    padding: 0px 7px;
    border-radius: 8px;
}

.Work_main_container_height_adjust {
    height: calc(var(--doc-height) - 250px);
}

@media (max-width: 769px) {
    .Work_main_container_height_adjust {
        height: calc(var(--doc-height) - 251px);
    }
}

.comboxBOXID .k-combobox span.k-clear-value {
    display: none;
}