.RegisterTerms .Term_main_box{
    padding: 0px 10%;
    text-align: justify;
    padding-bottom: 50px;
    background: transparent;
    height: auto;
    overflow-y: hidden;
}

.Term_main_box{
    padding: 0px 10%;
    text-align: justify;
    padding-bottom: 50px;
    background-color: white;
    height: 85vh;
    overflow-y: scroll;
}

.Term_title{
    text-align: center;
    font-weight: bold;
    padding: 40px 0px 20px;
    font-size: 18px;
}

.Term_des{
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
}

.Term_des_text_decoration{
    text-decoration: underline;
    color: blue;
}

.Term_des_title_box{
    display: flex;
    gap: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.Term_des_sec_child_box{
    margin-left: 40px;
    margin-bottom: 20px;
}

.Term_des_sec_child_box_first_child{
    display: flex;
    gap: 30px;
    line-height: 20px;
    margin-bottom: 15px;
}

.Term_des_sec_child_box_last_child{
    display: flex;
    gap: 30px;
    line-height: 20px;
}