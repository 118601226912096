.Custom_stepper .k-stepper .k-step-done .k-step-indicator{
    width: 16px !important;
    height: 16px !important;
    margin-left: 10px !important;
    background-color: #D12D2F !important;
    border-color: #D12D2F !important;
}

.Custom_stepper .k-stepper .k-step-current .k-step-indicator{
    width: 16px !important;
    height: 16px !important;
    margin-left: 10px !important;
    background-color: #D12D2F !important;
    border-color: #D12D2F !important;
}

.Custom_stepper .k-progressbar .k-selected {
    background: linear-gradient(to right, #D12D2F, #D12D2F);
}

.Custom_stepper .k-stepper .k-step-indicator{
    width: 16px;
    height: 16px;
    margin-left: 10px;
}

.Custom_stepper .k-step-link{
    align-items: start !important;
}

.Custom_stepper .k-step.k-step-first.k-step-done {
    max-height: unset !important;
}

.Custom_stepper .k-step .custom_label{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    min-height: 18px;
}

.Custom_stepper .k-step .custom_label1 {
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 14px;
    min-height: 18px;
    line-height: 18px;
    font-weight: 500;
}
.Custom_stepper .k-step .custom_label2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 900;
    min-height: 18px;
}

.Custom_stepper .k-step .custom_label {
    color: #776F6A
}

.Custom_stepper .k-step .custom_label1 {
    color: #776F6A
}

.Custom_stepper .k-step .custom_label2 {
    color: #776F6A
}

.Custom_stepper .k-focus .custom_label{
    color: black;
}
.Custom_stepper .k-focus .custom_label1 {
    color: black;
}

.Custom_stepper .k-focus .custom_label2 {
    color: black;
}

.Custom_stepper .k-step-done .custom_label{
    color: black;
}
.Custom_stepper .k-step-done .custom_label1{
    color: black;
}

.Custom_stepper .k-step-done .custom_label2 {
    color: black;
}

.custom_label, .custom_label1, .custom_label2{
    white-space: pre-line !important;
    line-height: 1.8 !important;
    width: 100%;
    max-width: unset !important;
    text-overflow: unset !important;
    /* overflow: unset !important; */
}

.Audit_box{
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background: #D12D2F;
}

.Audit_label1{
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.Audit_label2{
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.Custom_stepper ol li {
    max-height: unset !important;
}

.Audit_trail_text_box{
    max-width: 500px !important;
    margin: 0px 10px
}

.Audit_trail_custom_label{
    max-width: 360px !important
}

.Audit_trail_title {
    min-width: 350px;
    margin: 0px 20px 15px 20px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    position: sticky;
    background: white;
    height: 100px;
    display: flex;
    top: 0;
    align-items: center;
    z-index: 2;
}

.Custom_stepper .k-stepper .k-step-list {
    max-width: 90vw;
}

.k-step-list-vertical .k-step{
    min-height: 158px;
}

.Audit_trail_text_box::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.Audit_trail_text_box::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.Audit_trail_text_box::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.Audit_trail_text_box::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.Audit .k-window.k-dialog {
    max-height: 590px;
}

@media (max-width: 769px) {
    .Audit_trail_text_box{
        max-width: unset !important;
        margin: 0;
    }
    .Audit_trail_title{
        margin: 0px 0px 15px 0px;
    }
    .Audit_trail_custom_label{
        max-width: 100% !important;
    }
}

@media (max-width: 425px) {
    .Audit_trail_custom_label {
        max-width: 100% !important;
    }
}

@media (max-width: 375px){
    .Audit_trail_custom_label {
        max-width: 100% !important;
    }
}