.selector--dropdown {
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: none;
    border: 1px solid #E4E1DE;
    background-image: none;
    border-radius: 8px;
}

.selector--dropdown:focus {
    box-shadow: none;
}

.selector--dropdown .k-input-button .k-icon.k-i-caret-alt-down:before {
    content: none;
}

.selector--dropdown .k-input-button .k-icon.k-i-caret-alt-down {
    background-image: url("/src/assets/images/incident/dropdown-arrow.svg");
    background-repeat: no-repeat;
    margin-top: 15px;
}