.confirm-msg-title {
  margin: 62px 17px 0px 20px;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #000000;
}

.confirm-msg-content{
  margin: 25px 17px 0px 20px;
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000000;
}

.confirm-msg-buttons {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
  margin: 44px 17px 27px 20px;
  gap: 12px;
}

.confirm-msg-btn {
  height: 48px;
  width: 48%;
  border: 1px solid #eb7930 !important;
  border-radius: 100px !important;
  font-family: "Montserrat-Bold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.confirm-msg-btn-1 {
  height: 48px;
  width: 98%;
  border: 1px solid #eb7930 !important;
  border-radius: 100px !important;
  font-family: "Montserrat-Bold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.confirm-msg-btn.cancel {
  background: #ffffff;
  color: #eb6e1f;
}

.confirm-msg-btn.cancel:hover {
  background: #ffffff;
  color: #eb6e1f;
}

.confirm-msg-btn.ok {
  background: #eb7930;
  color: #ffffff;
}

.confirm-msg-btn.ok:hover {
  background: #eb7930;
  color: #ffffff;
}

.confirm-msg-btn-1.ok {
  background: #eb7930;
  color: #ffffff;
}

.confirm-msg-btn-1.ok:hover {
  background: #eb7930;
  color: #ffffff;
}

@media (min-width: 769px) {
  .confirm-msg-dialog-wrapper {
    width: 387px;
  }
  .confirm-msg-buttons {
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .confirm-msg-title {
    margin: 57px 0px 0px 0px;
  }
  .confirm-msg-buttons {
    margin: 30px 0px 4px 0px;
  }
  .confirm-msg-dialog-wrapper {
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  }
  .confirm-msg-buttons {
    gap: 0px;
    margin-bottom: 0px;
  }
}


.remark-textarea {
    height: auto !important;
    background: #fff !important;
    border: 1px solid #E4E1DE !important;
    border-radius: 8px !important;
}