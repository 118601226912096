.hide {
  display: none !important;
}

.main-loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logout_button {
  color: #eb7930 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 15px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logout_button p:hover {
  color: #ffa800;
}

.logout_popup_box {
  margin-top: 40px;
  padding: 20px 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  line-height: 22px;
}

.logout_popup_action_box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.logout_popup_action_button {
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  /* color: #EB7930; */
  padding: 15px 42px;
  cursor: pointer;
}

.k-dialog {
  border-radius: 8px !important;
}

.confidentail_box{
  position: fixed;
    /* bottom: 0; */
  top: 0;
  left: 0;
    /* width: calc(100% - 100px); */
    width: 100%;
    background: #E4E1DE;
    font-weight: 700;
    font-size: 10px;
    font-family: 'Montserrat-Bold';
    line-height: 15px;
    color: #8B0000;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

@media (max-width: 769px) {
  .confidentail_box{
    width: 100%;
    top: 0;
    z-index: 99;
    font-size: 9px;
    height: 13px;
  }
}