table {
  width: 100%;
  text-align: left;
  border: 0;
  background: #efefef;
  border-collapse: collapse;
}

th,
td {
  padding: 0px 16px;
}

th {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #727272;
}

td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #776f6a;
}

#table {
  max-width: calc(100vw - 260px);
  overflow-x: auto;
}

.Table_header {
  gap: 10px;
  display: flex;
  align-items: center;
  height: 40px;
}

.Table_button {
  background: #eb7930;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  max-width: 155px;
  cursor: pointer;
}

.TableHandle {
  height: 60px;
  background: white;
  cursor: pointer;
}

.TableHandle:hover {
  background-color: #fafafa;
}

/* width */
#table::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#table::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
#table::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

/* Handle on hover */
#table::-webkit-scrollbar-thumb:hover {
  background: grey;
}


.Table_header_box{
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: left;
}

.Table_flex{
  flex: 1 1;
  overflow: hidden;
}

.Table_text_overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Table_header_text{
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #727272;
}

.Table_body_text{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #776f6a;
}

.Table_header{
  background-color: rgb(239, 239, 239);
  height: 40px;
  padding: 0 28px 0 20px;
}

.Table_body{
  background-color: white;
  height: 60px;
  padding: 0 20px;
  cursor: pointer;
}

.Table_body:hover {
  background-color: #fafafa;
}

.scrolledDIV::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.scrolledDIV::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.scrolledDIV::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.scrolledDIV::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.item_with_background{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    border-radius: 13px;
    border: 1px solid;
}

.Column_filter_main_box{
    position: absolute;
    top: 40px;
    right: -15px;
    width: 242px;
    background: white;
    /* border: 1px solid #eb7930; */
    padding: 20px 15px;
    border-radius: 5px;
    z-index: 999;
    box-shadow: 0 2px 6px 2px rgba(60, 64, 67, .15);
}

.Column_filter_search_icon{
    width: 30px;
    height: 40px;
    position: absolute;
}

.Column_filter_search_box{
    width: calc(100% - 45px);
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    line-height: 18px;
    padding-left: 25px;
    height: 35px;
    padding-right: 15px;
}

.Column_filter_item_box{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    height: 150px;
    overflow: auto;
}

.button_hover{
  padding: 15px 20px;
}

.button_hover:hover{
  background-color: #2021241f;
}

.Column_filter_footer_box{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.Column_filter_footer_cancel{
    flex: 1;
    height: 30px;
    border: 1px solid #EB7930;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #EB7930;
    cursor: pointer;
}

.Column_filter_footer_ok{
    flex: 1;
    height: 30px;
    border: 1px solid #EB7930;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #EB7930;
    cursor: pointer;
}

/* th,
td {
  text-align: left;
  padding: 16px;
} */


.eye_icon_table{
    height: 25px;
    width: 25px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table_sticky_column_detail{
    position: sticky;
    left: 0;
    z-index: 9;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0px;
    width: 100px;
    overflow: visible;
}

.table_sticky_column_title{
    position: sticky;
    left: 0;
    background: rgb(239, 239, 239);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 0px;
    width: 100px;
}

.table_tool_tips_main_box {
   position: absolute;
    left: 40px;
    top: -10px;
    border-radius: 8px;
    display: flex;
    flex-direction: row-reverse;
}

.table_tool_tips_text_box {
    padding: 10px;
    border-radius: 10px;
    background: white;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #eb7930;
}

.table_tool_tips {
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent #EB7930 transparent transparent;
}

.table_radio_main_box{
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  margin-bottom: 10px;
}


.error_message{
  font-style: italic;
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

@media (max-height: 770px) {
  .date_range_box{
    height: 150px;
    overflow-y: scroll;
  }
}