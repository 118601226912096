.pagination_main_box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    gap: 10px;
    padding: 10px 20px;
}

.pagination_item_box_unactive{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: #727272;
    border: 1px solid #EB7930;
}

.pagination_item_box_active{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EB6E1F;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: white;
}

.pagination_item_box_unactive:hover{
    background-color: #EB6E1F;
    color: white;
}

.pagination_item_row_count_main_box{
    margin-left: auto;
    display: flex;
    gap: 20px;
    align-items: center;
}

.pagination_item_row_count_title{
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: #727272;
}

.pagination_item_row_count_drop_down {
    height: 36px;
    width: 120px;
    background-color: #FFFFFF !important;
    box-shadow: none;
    border: 1px solid #E4E1DE !important;
    background-image: none !important;
    border-radius: 8px !important;
}

.pagination_item_row_count_drop_down:focus {
    box-shadow: none;
}

.pagination_item_row_count_drop_down .k-input-button .k-icon.k-i-caret-alt-down:before {
    content: none !important;
}

.pagination_item_row_count_drop_down .k-input-button .k-icon.k-i-caret-alt-down {
    background-image: url("/src/assets/images/incident/dropdown-arrow.svg") !important;
    background-repeat: no-repeat !important;
    margin-top: 15px !important;
}

.k-animation-container.k-animation-container-shown{
    z-index: 10003 !important;
}