.incident-list {
    height: calc(100vh - 81px);
    height: calc(var(--doc-height) - 81px);
    margin-bottom: 2px;
}

.OHS-inspection-form .Work_create .header-text {
    text-align: center;
}

.OHS-slider-items-wrapper {
    /* height: calc(100vh - 251px);
    height: calc(var(--doc-height) - 151px); */
    overflow-y: auto;
    /* height: calc(100vh - 251px); */
    height: calc(var(--doc-height) - 122px);
    overflow-y: auto;
}

.Scroll_main_box {
    height: calc(var(--doc-height) - 196px);
    overflow-y: auto;

}

.OHS_chips_box {
    display: flex;
    height: 43px;
    margin: 10px 0px;
    overflow-x: scroll;
    width: calc(100% - 50px);
    align-items: center;
}

.OHS_chips_box::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

.OHS_chips_sec_box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-left: 20px;
    cursor: pointer;
    min-height: 32px;
}

.OHS_chips_sec_box_active {
    background-color: #EB7930;
}

.OHS_chips_sec_box_unactive {
    background: #FFFFFF;
}

.OHS_chips_text {
    padding-left: 15px;
    padding-right: 15px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 14px;
    white-space: nowrap;
}

.OHS_chips_text1 {
    margin-left: 5px;
    min-width: 22px;
    margin-right: 10px;
    border-radius: 100px;
    height: 21px;
    width: 23px;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.OHS_chips_text_active {
    color: white;
}

.OHS_chips_text1_active {
    background: white;
    color: #EB7930;
}

.OHS_chips_text_unactive {
    color: #5C5146;
}

.OHS_chips_text1_unactive {
    background: #EFEFEF;
    color: #5C5146;
}

.OHS_Sidebar_list_main_box {
    background: #FFF8EF;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    height: 48px;
    align-items: center;
    margin-bottom: 5px;
}

.OHS_Sidebar_list_main_box_title {
    font-family: 'Montserrat-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #EB7930;
}

.OHS_Sidebar_list_child_box_main {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 0px;
    margin-bottom: 20px;
}

.OHS_Sidebar_list_child_box_text_title {
    font-family: 'Montserrat-Bold';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}


.OHS_Sidebar_list_child_box_child {
    display: flex;
    gap: 10px;
    text-align: left;
    min-height: 47px;
    align-items: center;
}

.OHS_Sidebar_list_child_box_text_box {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.OHS_Sidebar_list_child_box_text_detail_info {
    flex: 1;
    font-family: 'Montserrat-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    display: flex;
    align-items: center;
}

.OHS_Sidebar_border {
    border: 1px solid #EFEFEF;
    margin-bottom: 10px;
}

.OHS_Sidebar_plus_icon {
    position: absolute;
    background: #EB7930;
    bottom: 20px;
    width: 72px;
    height: 72px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    margin-left: -23px;
}

.OHS_Column_main_box {
    margin-top: 50px;
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    /* text-align: left; */
}

.OHS_Column_Checkbox .k-checkbox:checked,
.k-checkbox.k-checked {
    width: 24px;
    height: 24px;
}

.OHS_Column_Checkbox .k-checkbox {
    width: 24px;
    height: 24px;
}

.OHS_Column_Checkbox_child_item_box {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    align-items: center;
    margin-bottom: 10px;
}

.OHS_Column_Checkbox_child_item_first_child {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: 'Montserrat-Bold';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.OHS_Column_Checkbox_footer_button_box {
    margin: 20px 30px 0px 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.OHS_Column_Checkbox_footer_button_cancel {
    flex: 1 1;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EB7930;
    border-radius: 100px;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #EB7930;
    cursor: pointer;
}

.OHS_Column_Checkbox_footer_button_save {
    flex: 1 1;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    border: 1px solid #EB7930;
    border-radius: 100px;
    color: white;
    background: #EB7930;
    cursor: pointer;
}

.OHS_FORM_TAB_main_box {
    display: flex;
    padding: 0px 20px;
    height: 48px;
    align-items: center;
    font-family: 'Montserrat-Bold';
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #EB7930;
    /* box-shadow: 0px 4px 0px 0px #0000001A; */
    background: white;
    /* margin-top: -4px; */
    width: 100%;
    overflow-y: scroll;
}

.OHS_FORM_TAB_main_box::-webkit-scrollbar {
    display: none;
}

.OHS_FORM_TAB_child {
    /* border-bottom: 6px solid; */
    height: 48px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    min-width: 165px;
    justify-content: center;
    cursor: pointer;

}

.OHS_FORM_TAB_ICON_main_box {
    display: flex;
    align-items: center;
}

.OHS_FORM_TAB_Icon {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.OHS_FROM_TAB_new {
    font-size: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EB7930;
    border-radius: 10px;
    cursor: pointer;
}

.OHS_FROM_TAB_new:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.OHS_FORM_validation {
    font-family: "Montserrat-Medium";
    font-style: italic;
    font-size: 10px;
    line-height: 18px;
    color: red;
    margin-top: 5px;
}

.OHS_Filter_Main_box {
    width: 450px;
    height: 550px;
}

.OHS_Filter_sec_box {
    height: calc(100% - 135px);
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;
}

.OHS_Filter_child {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.OHS_Filter_child_title {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}

.OHS_Filter_footer_main_box {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    height: 48px;
    gap: 20px;
}

.OHS_Filter_footer_clear {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid #Eb7930;
    border-radius: 100px;
    color: #EB7930;
    background: white;
    cursor: pointer;
}

.OHS_Filter_footer_apply {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid #Eb7930;
    border-radius: 100px;
    color: white;
    background: #EB7930;
    cursor: pointer;
}

.OHS_Filter_loader {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.OHS_Filter_cross_button {
    position: absolute;
    top: -25px;
    right: 15px;
    cursor: pointer;
}

.OHS_Create_Detail_box {
    height: calc(100% - 220px);
    width: 100%;
}

.OHS_Create_Detail_box .custom_css {
    width: calc(100% - 100px);
}

.Color_GREY {
    color: #776F6A;
}

.OHS_Export_option_box {
    position: absolute;
    top: -105px;
    z-index: 9999;
    right: 5px;
    /* right: 40px; */
    width: 60px;
    background: white;
    box-shadow: 0 2px 6px 2px rgba(60, 64, 67, .15);
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    border-radius: 5px;
}

.OHS_Export_icon {
    flex: 1 1 0%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.OHS_Export_icon:hover {
    color: #Eb7930;
}

.OHS_error_text {
    font-family: "Montserrat-Medium";
    font-style: italic;
    font-size: 10px;
    line-height: 18px;
    color: red;
    margin-top: 5px;
    flex: 1;
}

.OHS-filter-date-input .k-input-button {
    background-color: #ffffff;
    border: 0px !important;
    background-image: none !important;
}

.OHS-filter-date-input {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    margin-top: 10px !important;
}

.OHS-list-search-div {
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin: 0 1px 0 1px;
    margin-bottom: 2px;
}

.OHS-list-search-div .textbox {
    border: 0px !important;
    border-radius: 0;
    height: 48px;
    flex-grow: 1;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
}

.OHS-list-search-div .textbox:focus {
    outline-width: 0;
}

.OHS-list-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.OHS-list-reset-icon {
    cursor: pointer;
}

.OHS-list-icon {
    margin-left: 10px;
    margin-right: 10px;
}

.OHS_pagination_box {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0px 40px 0px 0px;
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #727272;
    height: 30px;
}

.OHS_PDF_popup_box {
    margin-top: 30px;
    width: 350px;
}

.OHS_PDF_popup_title {
    font-family: 'Montserrat-Medium';
    font-size: 15px;
    line-height: 21.94px;
    text-align: center;
}

.OHS_PDF_Download_button_box {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.OHS_PDF_Button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    background: #EB7930;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.OHS_Email_input {
    background-color: #FFFFFF !important;
    border: 1px solid #E4E1DE !important;
    border-radius: 8px !important;
    height: 35px;
}

.OHS_Email_main_box {
    padding-top: 30px;
    width: 300px;
    margin: 0px 20px 20px;
}

.OHS_Email_title {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}

.OHS_Email_child_box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}

.OHS_Email_footer_box {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    height: 48px;
    justify-content: center;
    align-items: center;
}

.OHS_Email_footer_cancel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: white;
    color: #EB7930;
    border-radius: 100px;
    cursor: pointer;
    border: 1px solid #EB7930;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.OHS_Email_footer_send {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background: #EB7930;
    color: white;
    border-radius: 100px;
    cursor: pointer;
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.OHS_Pagination_main_box{
    position: fixed;
    bottom: 0;
    width: calc(100% - 100px);
    box-shadow: 0px -4px 16px 0px #0000000F;
    background: white;
}

.OHS_Export_icon_tooltip_hover_main{
    position: absolute;
    right: 80px;
    top: -3px;
    border-radius: 8px;
    display: flex;
    color: #EB7930;
}

.OHS_Export_icon_tooltip_hover_text{
    padding: 10px;
    border-radius: 10px;
    background: white;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #eb7930;
}

.OHS_Export_icon_tooltip_hover_tips{
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -7px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent #EB7930;
}

.k-animation-container {
  z-index: 10001 !important;
}

/* .k-dialog-wrapper{
  z-index: 100 !important;
} */

@media (max-width: 769px) {
    .OHS_Create_Detail_box {
        height: calc(100% - 193px)
    }

    .OHS_Create_Detail_box .custom_css {
        width: 100%;
        height: calc(100% - 252px);
    }

    .custom_footer {
        gap: 8px;
    }

    .custom_footer .footer-left {
        flex: 1 33%;
    }

    .custom_footer .footer-right {
        flex: 1 66%;
    }

    .custom_footer .footer-left .Work_footer_button {
        min-width: unset;
        flex: 1;
    }

    .custom_footer .footer-right .Work_footer_button {
        min-width: unset;
        flex: 1;
    }

    .OHS_Filter_Main_box {
        max-width: none;
        width: 100%;
        height: 80vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .OHS_Filter_sec_box {
        height: calc(100% - 180px);
    }

    .OHS_Filter_cross_button {
        top: 30px;
    }

    .k-dialog-wrapper .k-dialog {
        position: absolute !important;
        bottom: 0;
        border-radius: 8px 8px 0px 0px;
        width: 100%;
    }
}

@media (max-width: 425px){
     .custom_footer .footer-right .Work_footer_button,
     .custom_footer .footer-left .Work_footer_button {
        font-size: 12px;
        overflow: hidden;
        text-overflow: clip;
        white-space: normal;
        font-size: 10px;
        /* border-radius: 15px !important; */
    }
}

@media (max-width: 375px) {
    .custom_footer .footer-right .Work_footer_button {
        font-size: 12px;
    }
    .custom_footer .footer-left .Work_footer_button {
        font-size: 12px;
    }
}

